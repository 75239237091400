.openlogin {
  background: none !important;
  border: none !important;
}

.openloginLogo {
  width: 100%;
  height: auto;
  max-width: 50px;
  margin: 0 10px;
}
/*
.primaryButton {
  width: 100% !important;
  margin-top: 20px !important;
  background: #F7DA06 !important;
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: 3px !important;
  border: 1px solid #000 !important;
}

.secondaryButton {
  width: 100% !important;
  margin-top: 5px;
  background: #000 !important;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: 3px !important;
  border: 1px solid #000 !important;
} */

.openlogin-logo {
  width: 100%;
}

button.signin {
  background: #fff !important;
  border-radius: 10px;
  height: 56px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 0;
}

button.signinGoogle {
  background: #fff !important;
  border-radius: 10px;
  height: 56px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 0;
}

button.signinApple {
  background: #fff !important;
  border-radius: 10px;
  height: 56px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 0;
}

.signinLogo {

}

button.signin p {
  color: #000;
  font-weight: 600;
}

button.signinGoogle p {
  color: #000;
  font-weight: 600;
}
button.signinApple p {
  color: #000;
  font-weight: 600;
}

.title {
  color: #e0e0e0;
  margin-top: 40px;
  margin-bottom: 15px;
  text-shadow: 1px 1px 3px #1e1e1ea3;
}
