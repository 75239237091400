.debugContainer {
  font-size: 12px;
  line-height: 14px;
  background: #b2c2ff !important;
  padding: 10px;
  font-weight: 600;
  width: 200px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  border: 2px solid #7a96ba;
  text-align: left;
}

.debugContainer > p {
  word-break: break-all;
}

.heading {
  text-align: center;
}
