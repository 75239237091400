@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* font-family: "Prata", serif; */

body,
html {
  font-family: "Futura", "Poppins", sans-serif !important;
}

.App {
  text-align: center;
  background: #f3f3f3;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}


/* App CSS */
:root {
  --theme-btn: linear-gradient(60deg, #d41d5c 10.72%, #2ea9e1 89.27%);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

section.header-main {
  background: #121212;
  /* box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25); */
  top: 0;
  width: 100%;
  z-index: 1;
  position: relative;
  padding: 12px 0;
}

/*  */
.navbar-brand {
  font-size: 45px;
}
.navbar-brand.logo {
  margin: auto !important;
}
button.btn-primary {
  box-shadow: 1px 1px 2px #595959;
}
button.close-menu {
  display: none;
}

.Collection-box img.shadow,
.Collection-box video {
  box-shadow: 1px 1px 3px #595959c9;
}
.add-wallet-wrap {
  position: absolute;
  right: 210px;
}
section.home-banner {
  background-image: url(../public/images/hero_bg-optimized.jpg);
  background-size: cover;
  background-position: center;
  padding: 60px 0 200px;
  background-repeat: no-repeat;
  /* background-color: #ccc; */
  /* background: radial-gradient(circle, rgb(12, 20, 140) 0%, rgb(119, 21, 133) 100%); */
}
section.createnft-main {
  background: #000;
}
.inner-wrap {
  width: 65%;
  margin: 0px auto;
  position: relative;
  top: -140px;
}
.createnft-top {
  background: radial-gradient(circle, rgb(69, 69, 69) 0%, rgb(22, 22, 22) 100%);
  /* background: #333; */
  box-shadow: 1px 1px 7px #383838a1;
  border-radius: 15px;
  padding: 40px 140px 40px 140px;
}
.claim-promo > * {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 15px 0 30px 0;
}
.createbotton-box p {
  font-size: 16px;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}
.createbotton-box a:hover {
  color: #fff !important;
}
.claim-promo button {
  width: 50%;
  padding: 11px 0;
}
.validating.claim-now  {
  padding: 6px 0 !important;
}
.promo-code {
  /* width: 50%; */
}
.claim-promo form div {
  margin: 0px !important;
}
.claim-promo button:focus,
button.see-collection:focus,
button.collection-btn:focus,
.sign-btn button:focus {
  box-shadow: unset !important;
}
.sign-btn button:active {
  background-color: transparent !important;
}
.claim-promo input {
  background: #fff;
  border: 2px solid #cfcfcf;
  color: #000 !important;
  text-align: center;
  width: 100%;
  padding: 11px 0;
}
.claim-promo input:focus {
  box-shadow: unset;
}
.createbotton-box {
  background: radial-gradient(circle, rgb(61, 209, 233) 0%, rgb(8, 69, 142) 100%);
  border-radius: 15px;
  padding: 20px 20px;
}
.createbotton-box.snap {
  background: radial-gradient(circle,#c0c310 0,#e3a600 100%) !important;
}
.inner-wrap .row {
  row-gap: 24px;
}
.create-nft-head p {
  margin: 12px 0;
  color: #e0e0e0;
}
.create-nft-head h3 {
  margin: 10px 0;
  text-shadow: 1px 1px 3px #050505a3;
  color: #fff;
}
.universal-store {
  margin-top: 20px;
  color: #fff;
  background: black;
  padding: 10px;
}
.universal-store-2 {
  margin-top: 10px;
  color: #fff;
  background: black;
  padding: 10px;
}
.universal-store,
.universal-store-2 {
  display: block;
  text-decoration: none;
}
.snap .universal-store {
  margin-top: 40px;
}
.universal-store-claim {
  margin-top: 30px !important;
}
.universal-store-claim a:hover {
  color: #fff !important;
}
section.import-wallet {
  background: #000;
  /* padding: 00px 0 110px; */
  /* margin-top: -40px; */
}
section.FAQ {
  background: #000;
  padding: 100px 0;
  margin-top: -140px;
}
section.top-leader {
  background: #000;
}
section.top-leader {
  background: #000;
  padding: 00px 0 110px;
  margin-top: -40px;
}
section.import-wallet {
  margin-top: 80px;
  margin-bottom: 20px;
}
section.import-wallet button {
  margin-top: -40px;
}
section.import-wallet img {
  max-width: 100px;
}
.modal-90w {
  max-width: 90vw !important;
}
.import-wallet-modal {
  text-align: center;
}
.import-wallet-modal .step-header {
  background: #eee;
  padding: 10px 5px 3px 5px;
  margin-bottom: 10px;
}
.import-wallet-modal .step-header > h5 {
  font-weight: 600;
  font-size: 16px;
}
.import-wallet-modal .step-header + p {
  font-size: 13px;
  font-style: italic;
}
.import-wallet-modal .modal-body {
  padding-bottom: 35px;
}
.import-wallet-modal img {
  max-width: 100%;
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
  margin-top: 0;
}
.import-wallet-modal .setup-network h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}
.import-wallet-modal .pkey-container {
  /* height: calc(100% - 294px); */
}
.import-wallet-modal .login-metamask {
  /* height: calc(100% - 96px); */
}
.import-wallet-modal .import-account {
  /* height: calc(100% - 96px); */
}
.import-wallet-modal .import-nfts {
  /* height: calc(100% - 116px); */
}
.import-wallet-modal .import-nfts p {
  word-break: break-all;
}
.import-wallet-modal .import-nfts .description {
  font-size: 13px;
  word-break: normal;
}
.import-wallet-modal .import-nfts table {
  margin-top: 20px;
  margin-bottom: 14px;
}
.import-wallet-modal input {
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
}
.import-wallet-modal h6 {
  font-weight: bold;
}
.import-wallet-modal h6.subtitle1 {
  font-weight: bold;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-bottom: -15px;
  margin-top: 10px;
}
.import-wallet-modal .pkey-container h6 {
  margin-top: calc(50% - 30px);
}
.import-wallet-modal .pkey-warning {
  font-size: 13px;
  color: #fb5252;
  margin-bottom: 0;
  width: 80%;
  margin: 0 auto;
}
.import-wallet-modal .subtitle {
  margin-top: -30px;
}
.topleader-wrap {
  background: #fff;
  display: flex;
  align-items: center;
  text-align: left;
  border-radius: 15px;
  overflow: hidden;
}
.topleader-wrap-open {
  background: #fff;
  display: flex;
  align-items: center;
  text-align: left;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
}
.top-leaderleft {
  background: #f1f1f1;
  min-width: 120px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0;
}
p.top-leader-badge {
  margin: 0;
  background: #474646;
  text-align: center;
  padding: 8px 20px;
  border-radius: 50px;
  color: #fff;
  font-size: 13px;
  width: 120px;
  margin-bottom: 5px;
}
.top-leaderright {
  width: 80%;
  padding: 12px 12px 12px 25px;
}
p.code-top {
  margin: 0;
  /* max-width: 80%; */
  font-size: 14px;
  word-break: break-all;
}
p.shares-topleader {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}
button.view-leader {
  background: transparent;
  border: none;
  color: #0064f9;
  font-size: 14px;
  padding: 0;
}
button.view-leader:hover {
  background: unset;
  color: #0064f9;
}
.copyright p {
  margin: 0;
  padding: 20px;
  color: #323232;
  display: inline;
}
.read-faq {
  padding: 20px 0 0px;
}
.secondaryBtn {
  background: #fff;
  border: none;
  padding: 16px 60px;
  color: #111;
  font-weight: 600;
  text-decoration: none;
  width: 250px;
  display: block;
  margin: 0 auto;
  margin-top: 15px;
  box-shadow: none !important;
}
.secondaryBtn:hover {
  cursor: pointer;
  color: #000;
}
.createbotton-box h5 {
  padding: 20px 0 7px;
  font-weight: 600;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}
a.digiExperienceLink {
  color: #fff;
  font-weight: 500;
}
.read-faq h2 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 700;
}
.sign-btn {
  display: flex;
  justify-content: center;
  gap: 11px;
  padding: 16px 0 0;
}
.sign-btn button,
.sign-btn button:hover {
  background: transparent;
  border: none;
}
.nav-link.sigb-upcol {
  color: #fff;
  background: none;
  border: none;
}
.nav-link:hover {
  background: none !important;
}
.nav-link.sigb-upcol:hover {
  color: #0064f9;
}
.sign-topleader-head h2 {
  font-size: 48px;
  color: #fff;
  font-weight: 700;
}
.sign-topleader-head p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 50px;
}
.banner-head h1 {
  font-weight: 700;
  color: #fff;
  font-size: 52px;
  text-shadow: 2px 2px 1px #000;
}
.placeholder-img {
  margin: 50px 0;
}
.placeholder-img img {
  height: 295px;
  width: 320px;
  object-fit: cover;
}
.banner-head p {
  font-size: 18px;
  padding: 10px 255px;
  margin: 0;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  word-break: break-all;
}
.FaQacccordion {
  padding: 40px 80px;
  background: #fff;
  border-radius: 15px;
}
h3.faq-head {
  margin-bottom: 30px;
  font-weight: 700;
}
.FaQacccordion .accordion-body {
  text-align: left;
  font-size: 15px;
  color: #111;
}
.FaQacccordion .accordion-body a {
  font-weight: 700;
  text-decoration: none;
}
.FaQacccordion .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: unset;
  color: #000;
}
.FaQacccordion .accordion-button:focus {
  box-shadow: unset;
}
section.faq-main {
  background: #000;
}
.collection-head h3 {
  font-weight: 600;
}
.collection-head p {
  font-weight: 700;
  font-size: 18px;
  margin: 10px 0 26px 0;
}
.Collection-box {
  background: #fff;
  border-radius: 15px;
  padding: 50px;
  margin: 30px 0 0 0;
}
.Collection-content {
  text-align: left;
  /* padding: 74px 0; */
}
.Collection-content h4 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  padding: 20px 0 45px;
  padding-top: 10px;
  margin: 0;
  word-break: break-word;
  color: #2b2b2b;
}
.Collection-content p {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}
button.collection-btn {
  padding: 14px 50px;
  border-radius: 6px;
}
.collection-img-here {
  /* height: 359px;
  width: 359px; */
}
.collection-img-here img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin: 0 auto;
}
.collection-box-head h3 {
  font-weight: 600;
  margin: 0;
}
img.home-thsirt {
  width: 100%;
}
img.home-shirt.shadow {
  box-shadow: 1px 1px 4px #222;
}
img.collection-thsirt {
  width: 50%;
}
button.close-btn {
  position: absolute;
  right: 17px;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
}
button.close-btn:hover {
  background: none;
}
.create-nft-head h3 {
  font-style: normal;
  font-size: 34px;
  font-weight: 600;
}
img.img-fluid.men-aaa {
  position: absolute;
  left: 0;
  top: -10px;
}
button.claim-now {
  background-color: #2f66f6;
}
p.enter-p {
  font-weight: 600;
  font-size: 16px;
  color: #e0e0e0;
  margin-top: 20px;
}
a {
  color: #2f66f6;
}
a:hover {
  color: #000 !important;
}
.inner-wraptop {
  width: 65%;
  margin: 0px auto;
}
.topleaders-head h2 {
  font-size: 44px;
  color: #fff;
  font-weight: 700;
}
.topleaders-head p {
  font-size: 18px;
  color: #fff;
  max-width: 1024px;
  margin: 0 auto;
  margin-bottom: 60px;
}
.view-leaders {
  background: none !important;
  padding: 0 !important;
  border: none !important;
  font-size: 14px !important;
}
.view-leaders:active,
.view-leaders:focus {
  background: none;
  color: #0d6efd !important;
}
.view-leaders:hover {
  background: none;
  border-color: #fff;
  color: #0d6efd !important;
}
.inner-wraptop .row {
  row-gap: 23px;
}
button.see-collection {
  padding: 12px 24px;
  background: #2f66f6;
  border-radius: 6px;
  margin-top: 20px;
}
.capturemodal .modal-dialog.modal-lg {
  /* max-width: 660px !important; */
  max-width: 35% !important;
}
.popcontent {
  padding: 30px 0;
}
.popcontent h4 {
  font-weight: 600;
  margin-bottom: 20px;
}
.createbotton-box img.img-fluid.bg-pick {
  /* height: 180px; */
  width: auto;
  border-radius: 10px;
}
.signing-promo.sign-up {
  display: none;
}
.or-col:before {
  content: "";
  height: 1px;
  width: 80%;
  background: #ccc;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0px auto;
  top: 34px;
}
.or-col p {
  background: #fff;
  position: absolute;
  width: 50px;
  left: 0;
  right: 0;
  margin: 0px auto;
}
.or-col {
  padding: 25px 0;
  display: none;
}

section.footer-main {
  padding: 30px 0;
}

section.footer-main a {
  text-decoration: none;
  color: #484848;
  margin: 0 10px;
}

section.footer-main a:hover {
  text-decoration: underline;
}

section.leaderboard {
  background: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top: 1px solid #B6B6B6;
}

section.top-leader .img-fluid {
  width: 120px !important;
  max-width: 120px !important;
}

section.leaderboard td {
  border: none;
  font-size: 13px;
  /* border-bottom: 1px solid #ccc; */
}

section.leaderboard {
  height: 350px;
}

section.leaderboard td.owner {
  word-break: break-all;
  text-align: left;
}

.error {
  color: #ff3939 !important;
  font-weight: 300 !important;
}

section.digital-experiences {
  background: #000 !important;
  padding-top: 120px;
  margin-top: -60px;
}

/*  */

/* responsive start */
@media only screen and (max-width: 1360px) {
}

@media only screen and (max-width: 1199px) {
  button.collection-btn {
    width: 100%;
  }

  /* .import-wallet-modal .modal-dialog,
  .import-wallet-modal .modal-content,
  .import-wallet-modal .modal-body {
    width: 900px;
  }

  .import-wallet-modal .pkey-container {
    height: calc(100% - 199px);
  }
  .import-wallet-modal .import-account {
    height: calc(100% - 118px);
  }
  .import-wallet-modal .import-nfts {
    height: calc(100% - 197px);
  } */

  .import-wallet-modal img {
    border: 1px solid #e0e0e0;
    margin-bottom: 40px !important;
    margin-top: 0px;
  }

  .import-wallet-modal .pkey-container {
    height: auto !important;
    margin-bottom: 30px;
  }

  .import-wallet-modal .setup-network img {
    margin-bottom: 20px !important;
  }

  .import-wallet-modal .setup-network h6 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .import-wallet-modal .import-nfts {
    height: auto !important;
    margin-bottom: 30px;
  }

  .import-wallet-modal h5 {
    margin-top: 5px;
  }

  /* .import-wallet-modal h5.step-3 {
    margin-top: 15px !important;
  } */

  .import-wallet-modal .pkey-container  h6 {
    margin-top: 0;
  }

  .import-wallet-modal .col {
    margin-top: 19px;
    border-top: 1px solid #e0e0e0;
    padding-top: 35px;
  }
}

@media only screen and (max-width: 1169px) {
  .container {
    max-width: 96% !important;
  }
}

@media only screen and (max-width: 1099px) {
  .capturemodal .modal-dialog.modal-lg {
    /* max-width: 660px !important; */
    max-width: 50% !important;
  }

  /* hangburger menu */
  ul.navbar-nav {
    position: absolute;
    top: 0;
    background: var(--theme-color);
    left: -400px;
    width: 370px !important;
    z-index: 999;
    transition: 0.3s;
    height: 100vh;
    padding: 50px 0 0;
    box-shadow: 5px 5px 12px #11111157;
    display: block;
  }
  .show ul.navbar-nav {
    left: 0;
    transition: 0.3s !important;
    display: block;
    height: 100ch;
    background-color: #fff;
  }
  .show ul.navbar-nav li a {
    color: #111;
    text-align: left;
  }
  nav.navbar {
    width: 52%;
    position: inherit;
  }
  .navbar-collapse {
    transition: 3s;
  }
  button.close-menu {
    display: block;
  }
  .navbar-expand-lg .navbar-toggler {
    display: block !important;
    position: relative;
    height: 22px;
    width: 36px;
    position: absolute !important;
    right: 30px;
  }
  span.bar {
    background: #fff;
    height: 2px;
    width: 28px;
    position: absolute;
    left: 0;
  }
  span.bar:first-child {
    top: 0;
  }
  span.bar:last-child {
    bottom: 0;
  }
  button.close-menu {
    display: block;
    position: absolute;
    top: 3px;
    right: 0;
    background: transparent;
    border: none;
  }
  ul.navbar-nav li a {
    font-size: 16px;
    color: #fff;
  }
  .navbar-toggler:focus {
    box-shadow: unset !important;
  }
  .add-wallet-wrap a.nav-link.sigb-up-link {
    margin-right: 60px;
  }
  button.close-menu {
    display: block;
  }
  /* hangburger menu */

  .add-wallet-wrap {
    right: 180px;
  }
  .inner-wrap,
  .inner-wraptop {
    width: 85%;
  }
  .topleaders-head p {
    padding: 0px 110px;
  }
}

@media only screen and (max-width: 991px) {
  /* .import-wallet-modal .pkey-container {
    height: auto !important;
    margin-bottom: 30px;
  }

  .import-wallet-modal .import-nfts {
    height: auto !important;
    margin-bottom: 30px;
  }

  .import-wallet-modal h5 {
    margin-top: 5px;
  }

  .import-wallet-modal .pkey-container  h6 {
    margin-top: 0;
  }

  .import-wallet-modal .col {
    margin-top: 19px;
    border-top: 1px solid #e0e0e0;
    padding-top: 35px;
  } */
}

@media only screen and (max-width: 1023px) {
}
@media only screen and (max-width: 979px) {
}

@media only screen and (max-width: 799px) {
  .inner-wrap,
  .inner-wraptop {
    width: 100%;
  }
  .createbotton-box p {
    font-size: 14px;
  }
  .add-wallet-wrap {
    right: 15px;
  }
  .topleaders-head p {
    padding: 0px 15px;
    font-size: 16px;
  }
  .create-nft-head h3 {
    /* font-size: 23px; */
  }
  .banner-head p {
    padding: 10px 15px;
  }
  section.footer-main a,
  section.footer-main p {
    display: block;
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 639px) {
  .capturemodal .modal-dialog.modal-lg {
    /* max-width: 660px !important; */
    max-width: 660px !important;
  }
  .claim-promo form {
    display: block;
  }
  .createnft-top {
    padding: 40px 30px;
  }
  .claim-promo button {
    width: 100%;
  }
  button.promo-code {
    margin-bottom: 12px;
  }
  .claim-promo input {
    margin-bottom: 12px;
  }
  section.top-leader {
    margin-top: -80px;
  }
  .read-faq {
    padding: 0px;
  }
  section.top-leader {
    padding: 0px 0 150px;
  }
  .banner-head h1 {
    font-size: 36px;
  }
  .read-faq h2 {
    font-size: 44px;
    padding: 0px 70px;
  }
  .create-nft-head h3 {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .signing-promo.sign-up {
    display: block;
  }
  .or-col {
    display: block;
  }
  .signing-promo.signing {
    margin-top: 30px;
  }
  .Collection-content {
    text-align: center;
    padding: 0;
  }
  .collection-img-here img,
  .collection-img-here {
    height: 100%;
    width: 100%;
  }
  .Collection-content h4 {
    padding: 20px 0 20px;
  }
}

@media only screen and (max-width: 479px) {

}

@media only screen and (max-width: 386px) {
  .navbar-brand {
    width: 145px;
  }
  .Collection-box {
    padding: 20px;
  }
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 359px) {
  .read-faq h2 {
    font-size: 27px;
    padding: 0 49px;
  }
  .createbotton-box img.img-fluid.bg-pick {
    height: 130px;
    width: auto;
  }
  img.img-fluid.men-aaa {
    width: 109px;
  }
  button.collection-btn {
    padding: 14px 40px;
  }
}
